// Coordenadas dos endereços
let map1 = $("#map_store_1_data");
let map2 = $("#map_store_2_data");
var coordenadas = [
    { lat: map1.attr('lat'), lng: map1.attr('lng'), title: map1.attr('address') },
    { lat: map2.attr('lat'), lng: map2.attr('lng'), title: map2.attr('address') }
];

// Criando o mapa com a posição centralizada entre os dois endereços
var map = L.map('mapa').setView([-29.1665, -51.1839], 15);

// Desabilitar o scroll do mouse
map.scrollWheelZoom.disable();

// Desabilitar o arrastar do mapa
map.dragging.disable();

// Criando uma camada de mapa
var layer = new L.TileLayer(
    "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
);
// Adicionando a camada ao mapa
map.addLayer(layer);

// Customizando ícone
let customIcon = {
    iconUrl: $("#map_store_icon").val(),
    iconSize: [50, 50],
    popupAnchor: [0, -67],
    setZIndex: 10,
};
let myIcon = L.icon(customIcon);

// Adicionando marcadores ao mapa
coordenadas.forEach(function(local) {
    let marker = new L.Marker([local.lat, local.lng], { icon: myIcon, title: local.title });
    marker.addTo(map);

    // Link para o Google Maps
    let googleMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${local.lat},${local.lng}`;

    // Bind popup com o link de "Como chegar"
    marker.bindPopup(`
      <p style="font-family: 'Trueno', sans-serif;">
         <b style='color:#212121; font-size: 18px;'>${local.title}</b><br>
         <a href="${googleMapsLink}" target="_blank" style="color: #fff;margin: 10px auto;display: table;" class="btn btn-gradient color-1 btn-sm"'>Como chegar</a>
      </p>
    `);
});
